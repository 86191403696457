import styled from "@emotion/styled";
import React from "react";
import Layout from "../components/layout";
import PaddedSection from "../components/sections/paddedSection";
import CoffeeBg from "../images/cover-bw-coffee.jpg";

const HeaderImage = styled.div`
  background: url(${CoffeeBg});
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 45vh;
  position: relative;
  z-index: -1;
`;

const HeaderSection = styled.header`
  background: #FFF;
  position: relative;
  z-index: 1;
  h1 {
    margin: 0;
    padding: 0;
    text-align: center;
    font-family: Comfortaa;
    font-weight: 300;
    font-size: 55px;
    margin-bottom: 0.6em;
  }

  h2 {
    font-family: Nunito Sans;
    font-weight: 400;
    font-size: 30px;
  }

  h3 {
    text-align: right;
  }
`;

const FloatingContent = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 90%;
  padding: 2em 2em 0em 2em;
  border-bottom: 20px solid black;
  transform: translate(-50%, -50%);
  background: #FFF;
`;

const StyledPaddedSection = styled(PaddedSection)`
  overflow: visible;
`;

const Header = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  padding: 1em;
  background: #FFF;
  border-bottom: 1px solid #333;
  z-index: 1000;
`;


export default ({ children, pageContext, ...props }) => {

  console.log(props);


  return (
    <Layout>
      <Header>
        Kacper Kula
      </Header>
      <StyledPaddedSection>
      <HeaderSection>
        <img src={CoffeeBg} />
        <FloatingContent>
          <h1>{pageContext.frontmatter.title}</h1>
          <h2>{pageContext.frontmatter.subtitle}</h2>
          <h3>by Kacper Kula on <date>Aug 12, 2020</date></h3>
        </FloatingContent>
      </HeaderSection>
      <div>{children}</div>
      </StyledPaddedSection>
    </Layout>
  );

}