import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/builds/kulak.technology/portfolio/src/layouts/post.jsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`You can also watch the content of this article on my YouTube channel:`}</p>
    <iframe width="560" height="315" src="https://www.youtube-nocookie.com/embed/etBTVXuif3Y" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
    <p>{`The dark themes become more and more popular. With the introduction of the dark system variants in Android 9+, macOS Mojave 10.14, and iOS 13, more and more apps and websites start to provide a dark version of their interface. Some offer an option to switch between the versions but it is also possible to detect the theme using CSS or JavaScript. In the following tutorial, I will show how to use these (depending on your needs) and also how to use it with React framework.`}</p>
    <h2>{`Detecting theme in CSS`}</h2>
    <p>{`To detect a dark theme in CSS we can use prefers-color-scheme media feature. Using it we can target either a light or a dark theme that the user has selected for their system.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`@media (prefers-color-scheme: light) {
  body {
    background: #FFF;
    color: #333;
  }
}
@media (prefers-color-scheme: dark) {
  body {
    background: #333;
    color: #FFF;
  }
}
`}</code></pre>
    <p><a parentName="p" {...{
        "href": "https://caniuse.com/prefers-color-scheme"
      }}>{`More information about browser support for prefers-color-scheme can be found on Can I Use website.`}</a></p>
    <h2>{`Detecting theme in JS`}</h2>
    <p>{`To detect light or dark theme in JavaScript we can use matchMedia function that allows checking programmatically whether a CSS media query has been fulfilled or not.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`const darkThemeMq = window.matchMedia("(prefers-color-scheme: dark)");
if (darkThemeMq.matches) {
  // Theme set to dark.
} else {
  // Theme set to light.
}
`}</code></pre>
    <p>{`Using this code snippet we can easily detect what theme the user has set. Note that the code checks the system theme only once and does not react to the dynamic changes of the theme (either manual but the theme might also change automatically depending on a time of the day). If we want to provide the best user experience, we should react to the change and switch our layout accordingly. Fortunately, MediaQueryList object (which is a result of matchMedia call) has a .addListener method which allows us to provide a calback which is exectued every time the media query result changes.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`const darkThemeMq = window.matchMedia("(prefers-color-scheme: dark)");
darkThemeMq.addListener(e => {
 if (e.matches) {
  // Theme set to dark.
 } else {
    // Theme set to light.
  }
});
`}</code></pre>
    <p><a parentName="p" {...{
        "href": "https://caniuse.com/#feat=matchmedia"
      }}>{`More information about browser support for matchMedia can be found on Can I Use website.`}</a></p>
    <h2>{`Detecting theme in React`}</h2>
    <p>{`In React (> 16.8) we can create custom hook that will indicate whether the theme is set to dark or not.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`const useThemeDetector = () => {
    const getCurrentTheme = () => window.matchMedia("(prefers-color-scheme: dark)").matches;
    const [isDarkTheme, setIsDarkTheme] = useState(getCurrentTheme());  
    const mqListener = (e => {
        setIsDarkTheme(e.matches);
    });
    
    useEffect(() => {
      const darkThemeMq = window.matchMedia("(prefers-color-scheme: dark)");
      darkThemeMq.addListener(mqListener);
      return () => darkThemeMq.removeListener(mqListener);
    }, []);
    return isDarkTheme;
}
`}</code></pre>
    <p>{`We can then use it in any component in the following way:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`const CurrentThemeComponent = () => {
  const isDarkTheme = useThemeDetector();
  return (
   <p>Current Theme is: {isDarkTheme ? "dark": "light"}</p>
  );
}
`}</code></pre>
    <h2>{`Debugging color schemes`}</h2>
    <p>{`When you develop a dark theme for your page you might want to switch back and forth between light and dark variant to check how your page renders. It might be quite time consuming to switch it on the system level — the whole procedure might get quite tedious. Fortunately, Safari, Chrome and Firefox allow for emulating the prefers-color-scheme value in their Developer Tools.`}</p>
    <h3>{`Emulating prefers-color-scheme in Chrome`}</h3>
    <p>{`In Chrome the option can be found in a Rendering tab which is accessible from the additional menu on the top right, under `}<em>{`More Tools`}</em>{`.`}</p>
    <h3>{`Emulating prefers-color-scheme in Safari`}</h3>
    <p>{`In Safari the Web Inspector includes a button to toggle dark or light mode.`}</p>
    <h3>{`Emulating prefers-color-scheme in Firefox`}</h3>
    <p>{`In Firefox theme can be changed using `}<inlineCode parentName="p">{`about:config`}</inlineCode>{` tab. All we need to do is to add a new property `}<inlineCode parentName="p">{`ui.systemUsesDarkTheme`}</inlineCode>{` of type Number. Value of 1 will set the Firefox to dark theme.`}</p>
    <h3>{`Testing dark mode in Cypress`}</h3>
    <p>{`You can also change theme of the browser in the Cypress tests. Thanks to that we can test how the page behaves in both light and dark variant and for example perform `}<a parentName="p" {...{
        "href": "https://github.com/avanslaars/cypress-axe"
      }}>{`accessibility color test`}</a>{`. For more details on that I recommend checking brilliant article `}<a parentName="p" {...{
        "href": "https://www.cypress.io/blog/2019/12/13/test-your-web-app-in-dark-mode/"
      }}>{`Test Your Web App in Dark Mode`}</a>{` published on Cypress Blog.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      